<template>
    <div class="main">
        <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="rgb(250, 250, 250)">
                <el-menu-item index="1">领料盘点出库</el-menu-item>
            </el-menu>
        </div>
        <div class="change_select_menu">
            <!-- <el-button style="margin-left: 20px;margin-right: 20px;" @click="change_updata"
                :loading="isSending">新建领料单</el-button> -->
            <el-input placeholder="搜索领料单" v-model="lld_data" style="margin-right: 5px;width: 10%;margin-left: 37px;">
            </el-input>
            <el-button @click="lld_out" :loading="isSending8">搜索领料单</el-button>
        </div>
        <div class="change">
            <div class="change_div1">
                <div class="sku_insert">
                    成品SKU:
                    <el-input placeholder="SKU" v-model="insert_sku" @keydown.native="handleKeydown"
                        style="margin-right: 5px;width: 20%;margin-left: 37px;"></el-input>
                    <el-input placeholder="领料数量" v-model="insert_llsl" @keydown.native="handleKeydown"
                        style="width: 8%;margin-left: 9px;"></el-input>
                    &nbsp&nbsp&nbsp
                    {{ this.sku_out + ' ' + this.pm_out }}
                </div>
                <div class="sku_loding">
                    <el-table :data="tableData_sku" style="width: 100%" height="100%">
                        <el-table-column width="15">
                        </el-table-column>
                        <el-table-column label="已录入：" type="index" :index="indexMethod" width="80">
                        </el-table-column>
                        <el-table-column prop="SKU" width="400">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.SKU }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="585">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.品名 }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="change_table1">
                    <el-table ref="multipleTable" :data="tableData" style="width: 100%" height="100%"
                        @cell-dblclick="doubleClickCell" @selection-change="handleSelectionChange"
                        :row-class-name="rowClassName">
                        <el-table-column width="15">
                        </el-table-column>
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="SKU" label="SKU" width="200">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.SKU }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="品名" label="品名" width="400">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.品名 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="数量" label="数量(可编辑)" width="130">
                            <template slot-scope="scope">
                                <div v-if="!scope.row.edit" slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.数量 }}
                                </div>
                                <el-input v-else v-model="scope.row.数量" @blur="levelChange(scope.row)">
                                    {{ scope.row.数量 }}
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="可用量" label="可用量" width="185">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.可用量 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="已使用量" label="已使用量" width="100">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.已使用量 }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="table_down">
                    <el-button style="margin-left: 165px;margin-top: 3px;width: 300px; height: 50px;"
                        @click="saveSelectedRows" type="primary" plain :loading="isSending">录入下个产品</el-button>
                    <el-button style="margin-left: 150px;margin-top: 3px;width: 300px; height: 50px;" @click="make_lld"
                        type="primary" plain>生成领料单</el-button>
                </div>
            </div>
        </div>
        <el-dialog title="生成领料单" :visible="dialogTableVisible_up" :before-close="handleClose_up">
            <el-table :data="cpsku" @selection-change="handleSelectionChange1">
                <el-table-column width="15">
                </el-table-column>
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="SKU" label="SKU" width="150">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.SKU }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="品名" label="品名" width="300">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.品名 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="数量" label="数量" width="190">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.数量 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="可用量" label="可用量" width="190">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.可用量 }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="make_donwnloadtable" :loading="isSending2">确定生成</el-button>
            </span>
        </el-dialog>

        <el-dialog title="物料出库" :visible="dialogTableVisible_out" :before-close="handleClose_out">
            领料单号：{{ this.lld_loading }}
            <el-table :data="tableData_out" @cell-dblclick="doubleClickCell">
                <el-table-column width="15">
                </el-table-column>
                <el-table-column prop="SKU" label="SKU" width="150">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.SKU }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="品名" label="品名" width="300">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.品名 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="数量" label="数量（可编辑）" width="230">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.edit" slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.数量 }}
                        </div>
                        <el-input v-else v-model="scope.row.数量" @blur="levelChange(scope.row)">
                            {{ scope.row.数量 }}
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="可用量" label="可用量" width="100">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.可用量 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="校正为0" width="100">
                    <template slot-scope="scope">
                        <!-- 使用 el-checkbox 组件 -->
                        <el-checkbox @change="handleCorrection(scope.row)" v-model="scope.row.isCorrected"></el-checkbox>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="lld_out_up()" :loading="isSending_out">确定出库</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            activeIndex: '1',
            insert_sku: '',
            insert_llsl: '',
            sku_out: '',
            pm_out: '',
            isSending: false,
            isSending_out: false,
            isSending_up: false,
            isSending_down: false,
            isSending8: false,
            isSending_pdf: false,
            isSending9: false,
            dialogTableVisible_up: false,
            dialogTableVisible_out: false,
            dialogTableVisible_down: false,
            form: {},
            tableData: [],
            tableData_sku: [],
            tableData_out: [],
            cpsku: [],
            selectedRows: [],
            selectedRows1: [],
            lld_data: '',
            url_download: '',
            lld_loading: '',
            monitor: []
        }
    },
    created() {
        this.init()
    },
    methods: {
        handleCorrection(row) {
            if (row.isCorrected) {
                // 勾选时，保存当前数量到 originalQuantity，并设置为可用量
                row.originalQuantity = row.数量;
                row.数量 = row.可用量;
            } else {
                // 取消勾选时，恢复原始数量
                row.数量 = row.originalQuantity;
            }
        },
        handleKeydown(event) {
            if (event.key === 'Enter') {
                this.handleEnter();
            }
        },
        handleEnter() {
            const monitor = this.tableData_sku.some(item => item.SKU === this.insert_sku)
            // 执行回车键对应的操作
            if (this.insert_sku == '') {
                alert('请先输入sku')
            } else if (monitor) {
                alert('已录有该sku')
            } else if (this.insert_llsl == '') {
                alert('请先输入领料数量')
            }
            else {
                this.select_sku()
            }
        },
        select_sku() {
            if (isNaN(this.insert_llsl)) {
                alert('领料数量请输入有效的乘数');
                return;
            }
            this.$axios.post('/vue/packing_out/select_sku', { sku: this.insert_sku }).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.tableData = res.data
                    const bMap = {};
                    this.cpsku.forEach(item => {
                        bMap[item.SKU] = item.数量 || 0; // 确保数量存在，默认为 0
                    });
                    // 遍历列表 a，并添加“已使用量”
                    this.tableData = this.tableData.map(item => {
                        if (bMap.hasOwnProperty(item.SKU)) {
                            return {
                                ...item,
                                "已使用量": bMap[item.SKU]
                            };
                        } else {
                            return {
                                ...item,
                                "已使用量": 0 // 如果没有匹配的 SKU，可以设置为 0 或其他默认值
                            };
                        }
                    });
                    this.tableData = this.tableData.map(item => ({
                        ...item,
                        数量: Number(item.数量) * this.insert_llsl
                    }));
                    this.sku_out = res.sku
                    this.pm_out = res.pm
                    this.insert_sku = ''
                    this.insert_llsl = ''
                } else if (res.code == 0) {
                    alert('未能找到该sku的信息')
                } else {
                    alert('获取失败')
                }
            })
                .catch(error => {
                    console.log(error)
                    alert('获取失败，发生未知错误')
                })
        },
        lld_out_up() {
            this.isSending_out = true
            const hasInvalidRows = this.tableData_out.some(row => {
                const quantity = Number(row.数量);
                const available = Number(row.可用量);
                return isNaN(quantity) || isNaN(available) || quantity > available;
            });
            if (hasInvalidRows) {
                alert('存在数量大于可用量的行，请检查后重新提交！');
                this.isSending_out = false
                return;
            }
            this.$axios.post('/vue/packing_out/lld_out_up', this.tableData_out).then(res => {
                console.log(res);
                if (res.code == 200) {
                    alert(res.data)
                    this.dialogTableVisible_out = false
                    this.isSending_out = false
                } else {
                    alert(res.msg)
                    this.isSending_out = false
                }
            })
                .console(error => {
                    console.log(error)
                    this.isSending_out = false
                })
        },
        lld_out() {
            if (this.lld_data == '') {
                alert('请先输入领料单')
                return;
            }
            this.$axios.post('/vue/packing_out/lld_out', { lld: this.lld_data }).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.tableData_out = res.data
                    this.tableData_out.forEach(row => {
                        row.originalQuantity = row.数量;
                    });
                    this.lld_loading = this.tableData_out[0]['领料单号']
                    this.dialogTableVisible_out = true
                } else {
                    alert('获取失败,没有该领料单号')
                }
            })
                .catch(error => {
                    console.log(error)
                    alert('发生错误:==' + '请检查内容是否填写正确')
                })
        },
        handleClose_up() {
            this.dialogTableVisible_up = false
        },
        handleClose_out() {
            this.dialogTableVisible_out = false
        },
        indexMethod(index) {
            return index + 1;
        },
        doubleClickCell(row) {
            this.$set(row, "edit", true)
        },
        levelChange(row) {
            this.$set(row, "edit", false)
        },
        handleSelectionChange(val) {
            this.selectedRows = val;
        },
        handleSelectionChange1(val) {
            this.selectedRows1 = val;
        },
        make_lld() {
            this.dialogTableVisible_up = true
        },
        make_donwnloadtable() {
            if (!this.selectedRows1 || this.selectedRows1.length === 0) {
                alert('没有选中的行！')
                console.warn('没有选中的行！');
                return;
            }
            const hasInvalidRows = this.selectedRows1.some(row => {
                const quantity = Number(row.数量);
                const available = Number(row.可用量);
                return isNaN(quantity) || isNaN(available) || quantity > available;
            });
            if (hasInvalidRows) {
                alert('存在数量大于可用量的行，请检查后重新提交！');
                return;
            }
            const selectedDict1 = [];
            this.selectedRows1.forEach(row => {
                selectedDict1.push({
                    "SKU": row.SKU,
                    "品名": row.品名,
                    "数量": row.数量,
                    "可用量": row.可用量
                });
            });
            console.log('选中的行字典:', selectedDict1);

            this.$axios.post('/vue/packing_out/make_donwnloadtable', selectedDict1).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.url_download = res.data
                    this.download_zip()
                } else {
                    alert('获取失败')
                }
            })
                .console(error => {
                    console.log(error)
                })

        },
        download_zip() {
            let url = "https://kebeidianzi.com/vue/packing_out/download_zip/" + this.url_download + '.zip'
            this.$axios.get(`/vue/packing_out/download_zip/` + this.url_download + '.zip').catch(error => {
                console.log("错误", error)
            })
            this.cpsku = []
            this.tableData_sku = []
            this.tableData = []
            window.open(url, '_blank')
        },
        saveSelectedRows() {
            if (!this.selectedRows || this.selectedRows.length === 0) {
                alert('没有选中的行！')
                console.warn('没有选中的行！');
                return;
            }
            const selectedDict = [];
            this.selectedRows.forEach(row => {
                selectedDict.push({
                    "SKU": row.SKU,
                    "品名": row.品名,
                    "数量": row.数量,
                    "可用量": row.可用量,
                });
            });
            console.log('选中的行字典:', selectedDict);

            selectedDict.forEach(row => {
                const existing = this.cpsku.find(item => item.SKU === row.SKU);
                if (existing) {
                    existing.数量 = Number(existing.数量) + Number(row.数量);
                } else {
                    this.cpsku.push({ ...row, 数量: Number(row.数量) });
                }
            });
            console.log('目前已录有:', this.cpsku);
            this.tableData_sku.push({ SKU: this.sku_out, 品名: this.pm_out })
            this.sku_out = ''
            this.pm_out = ''
            this.tableData = []
        },
        rowClassName({ row, rowIndex }) {
            // 检查数量 + 已使用量是否大于可用量
            if (
                Number(row.数量) + (Number(row.已使用量) || 0) > Number(row.可用量)
            ) {
                return 'highlight-row'; // 返回自定义的 CSS 类名
            }
            return ''; // 默认不添加任何类
        },
    }
}
</script>
  
<style>
.main {
    height: 100%;
    width: 100%;

}

.change_select_menu {
    width: 100%;
    height: 4.5%;
    background-color: rgb(250, 250, 250);
}

.change {
    /* border: 1px solid red; */
    height: 88.9%;
}

.change_div1 {
    width: 60%;
    height: 98%;
    margin: 20px;
    margin-top: 0px;
    float: left;

}

.change_table1 {
    width: 100%;
    border: 1px solid rgb(207, 207, 207);
    border-top: 0px;
    height: 62%;
}

.sku_loding {
    width: 100%;
    border: 1px solid rgb(207, 207, 207);
    height: 20%;
}

.sku_insert {
    width: 100%;
    height: 6%;

}

.table_down {
    width: 100%;

    height: 6%;
}

.change_table2 {
    width: 1500px;
    border: 2px solid rgb(228, 243, 12);
    height: 92%;
    margin-top: 47px;

}

.el-table .highlight-row {
    background-color: #f4f5ba !important;
}
</style>

<style scoped="scoped">
/* 定义高亮行的样式 */
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
</style>