<template>
  <div class="home">
    <div class="left_daohang">
      <el-menu
        default-active="1-4-1"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
      >
        <router-link to="/dashboard/main">
          <el-menu-item index="1">
            <i><img src="~@/assets/images/cobak.jpg" style="height: 23px" /></i>
          </el-menu-item>
        </router-link>
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-receiving"></i>
          </template>
          <el-menu-item-group>
            <span slot="title">仓库工具</span>
            <a
              href="https://kebeidianzi.com/html/static/templates/FBA_shipment.html"
            >
              <el-menu-item index="1-1">FBA出货</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/packing_page.html"
              ><el-menu-item index="1-2">物料装箱</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/search_page.html"
              ><el-menu-item index="1-3">物料信息查询</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/order_shipment.html"
              ><el-menu-item index="1-4">物料出库</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/order_put.html"
              ><el-menu-item index="1-5">物料入库</el-menu-item></a
            >
            <router-link to="/dashboard/change_l">
              <el-menu-item index="1-6">库存仓位管理</el-menu-item>
            </router-link>
            <router-link to="/dashboard/purchase_kanban">
              <el-menu-item index="1-7">加工质检</el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-box"></i>
          </template>
          <el-menu-item-group>
            <span slot="title">工厂物料管理工具</span>
            <a
              href="https://kebeidianzi.com/html/static/templates/stores_requisition.html"
              ><el-menu-item index="2-1">生成领料单</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/show_requisition.html"
              ><el-menu-item index="2-2">领料详情</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/permanent_store.html"
              ><el-menu-item index="2-3">常备物料管理</el-menu-item></a
            >
            <router-link to="/dashboard/packing_out">
              <el-menu-item index="2-4">领料盘点出库</el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-collection"></i>
          </template>
          <el-menu-item-group>
            <span slot="title">listing</span>
            <a
              href="https://kebeidianzi.com/html/static/templates/create_msku.html"
              ><el-menu-item index="3-1">新建Asin</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/change_fnsku.html"
              ><el-menu-item index="3-2">换标</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/pair_msku.html"
              ><el-menu-item index="3-3">配对</el-menu-item></a
            >
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-copy-document"></i>
          </template>
          <el-menu-item-group>
            <span slot="title">父体管理</span>
            <a
              href="https://kebeidianzi.com/html/static/templates/male_parent.html"
              ><el-menu-item index="4-1">本地父体管理</el-menu-item></a
            >
            <a
              href="https://kebeidianzi.com/html/static/templates/parent_message.html"
              ><el-menu-item index="4-2">父体对比</el-menu-item></a
            >
            <router-link to="/dashboard/auto_update">
              <el-menu-item index="4-3">自动移入移出</el-menu-item>
            </router-link>
            <router-link to="/dashboard/parent_calculate">
              <el-menu-item index="4-4">备货建议</el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-picture-outline"></i>
          </template>
          <el-menu-item-group>
            <span slot="title">图片管理</span>
            <a
              href="https://kebeidianzi.com/html/static/templates/upload_image.html"
              ><el-menu-item index="5-1">同步图片</el-menu-item></a
            >
            <router-link to="/dashboard/product">
              <el-menu-item index="4-1">产品信息</el-menu-item>
            </router-link>
            <router-link to="/dashboard/invoice">
              <el-menu-item index="4-1">货代发票</el-menu-item>
            </router-link>
            <router-link to="/dashboard/worker">
              <el-menu-item index="4-1">员工信息</el-menu-item>
            </router-link>
            <router-link to="/dashboard/process">
              <el-menu-item index="4-1">型号工序</el-menu-item>
            </router-link>
            <router-link to="/dashboard/record">
              <el-menu-item index="4-1">工时统计</el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-shopping-cart-full"></i>
          </template>
          <el-menu-item-group>
            <span slot="title">项目管理</span>
            <router-link to="/dashboard/review">
              <el-menu-item index="4-1">项目评审</el-menu-item>
            </router-link>
            <router-link to="/dashboard/auto_project">
              <el-menu-item index="4-1">维护项目管理</el-menu-item>
            </router-link>
            <router-link to="/dashboard/comment_m">
              <el-menu-item index="4-1">评论管理系统</el-menu-item>
            </router-link>
            <router-link to="/dashboard/monitor">
              <el-menu-item index="4-1">异常监控系统</el-menu-item>
            </router-link>
            <router-link to="/dashboard/sample">
              <el-menu-item index="4-1">样品监控系统</el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="7">
          <i class="el-icon-user"></i>
          <span slot="title">用户:{{ uname }}</span>
        </el-menu-item>
        <el-submenu index="8">
          <template slot="title">
            <i class="el-icon-data-line"></i>
          </template>
          <el-menu-item-group>
            <span slot="title">生产排序</span>
            <router-link to="/dashboard/vendor">
              <el-menu-item index="4-1">供应商管理</el-menu-item>
            </router-link>
            <router-link to="/dashboard/urgent_order">
              <el-menu-item index="4-1">生产加急单</el-menu-item>
            </router-link>
            <router-link to="/dashboard/timeout_order">
              <el-menu-item index="4-1">超期采购单</el-menu-item>
            </router-link>
            <router-link to="/dashboard/application">
              <el-menu-item index="4-1">我的申请</el-menu-item>
            </router-link>
            <router-link to="/dashboard/check">
              <el-menu-item index="4-1">我的审核</el-menu-item>
            </router-link>
            <router-link to="/dashbord/statistic">
              <el-menu-item index="4-1">超期情况</el-menu-item>
            </router-link>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
    <div class="body">
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: true,
      uname: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$axios.get("/vue/islog").then((res) => {
        if (res.code == 0) {
          this.$router.push({ path: "/login" });
        } else {
          this.uname = res.uname;
        }
      });
    },
  },
};
</script>

<style>
.home {
  height: 928px;
  width: 1900px;
}

.body {
  height: 100%;
  width: 96.6%;
  float: left;
}

.left_daohang {
  height: 100%;
  float: left;
  width: 64px;
}

.el-menu-vertical-demo {
  height: 100%;
}
</style>
