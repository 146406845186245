import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=d1525df8&scoped=true"
import script from "./Review.vue?vue&type=script&lang=js"
export * from "./Review.vue?vue&type=script&lang=js"
import style0 from "./Review.vue?vue&type=style&index=0&id=d1525df8&prod&lang=css"
import style1 from "./Review.vue?vue&type=style&index=1&id=d1525df8&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1525df8",
  null
  
)

export default component.exports