<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>
<style>
html,
body,
#app {
  height: 100%;
}

@import url("./assets/style/font.css");

* {
  font-family: HarmonyOS_SansSC;
  /* font-family: 'NanoWoodHei'; */
}
</style>
